<script lang='ts'>
  import { tick } from 'svelte'

  import { languages } from '@/config/languages'

  import { getIsoFromId } from '@/helpers/apiCall'

  import CountryFlag from '@/components/icons/CountryFlag.svelte'

  import { LanguagesList } from '@/definitions/langoid'

  export let iso = '',
    id: number = 0,
    variant: 'full' | 'small' | 'normal' = 'normal',
    clickOnFlag = (id: number) => { },
    active = false,
    rand = 0,
    tabindex = 0,
    noClick = false

  let {
    country,
    color,
    background,
    showText
  } = getDetails()
  const MAX_RAND_NUMBER = 1e6

  $:if (rand) {
    const tmp = getDetails()
    country = tmp.country
    color = tmp.color
    background = tmp.background
    showText = tmp.showText
  }

  if (rand === 0) {
    rand = Math.round(Math.random() * MAX_RAND_NUMBER)
  }
  if (iso === '') {
    iso = getIsoFromId(id)
  }

  function getDetails () {
    const pairs: Record<string, string> = {
      ara: 'sa',
      bel: 'by',
      ben: 'bd',
      bul: 'bg',
      ces: 'cz',
      cmn: 'cn',
      cze: 'cz',
      dan: 'dk',
      deu: 'de',
      ell: 'gr',
      eng: 'gb',
      est: 'ee',
      fas: 'ir',
      fin: 'fi',
      fra: 'fr',
      heb: 'il',
      hel: 'gr',
      hin: 'in',
      hrv: 'hr',
      hun: 'hu',
      hye: 'am',
      ind: 'id',
      ita: 'it',
      jpn: 'jp',
      kor: 'ko',
      lav: 'lv',
      lit: 'lt',
      mal: 'my',
      mkd: 'mk',
      msy: 'my',
      nld: 'nl',
      nor: 'no',
      pap: 'aw',
      pol: 'pl',
      por: 'pt',
      ron: 'ro',
      rus: 'ru',
      slk: 'sk',
      slv: 'si',
      spa: 'es',
      srp: 'rs',
      swe: 'se',
      tha: 'th',
      tur: 'tr',
      ukr: 'ua',
      vie: 'vn',
      zho: 'cn'
    }
    let country = '',
      color = 'black',
      background = 'transparent',
      showText = true
    if (['ceb', 'tgl'].includes(iso)) {
      country = 'ph'
      color = '#fff'
    } else if (iso === 'arb') {
      background = 'green'
      color = '#fff'
    } else if (iso === 'yor') {
      country = 'ng'
      color = 'black'
    } else if (pairs[iso]) {
      country = pairs[iso]
      showText = false
    } else {
      color = 'black'
      background = '#fff'
    }
    tick()
    return {
      background,
      color,
      country,
      showText
    }
  }

  const title = languages.find((l: LanguagesList) => l.iso === iso)?.name || ''
</script>
{#if country}
  <div
    class='flag-wrapper'
    class:-active={active}
    class:-full={variant === 'full'}
    class:-noClick={noClick}
    class:-small={variant === 'small'}
    data-id={id}
    data-rand={rand}
    role='button'
    {tabindex}
    {title}
    on:click={() => clickOnFlag(id)}
    on:keypress={() => {}}
    style:color
    style:background
  >
    <CountryFlag {country} size={variant==='small'?16:24} />
    {#if showText}
      <div class='language'>{iso}</div>
    {/if}
    {#if variant === 'full'}
      <div class='languageName'>{title}</div>
    {/if}
  </div>
{:else}
  <div
    class='flag-wrapper'
    class:-active={active}
    class:-small={variant === 'small'}
    data-id={id}
    data-rand={rand}
    role='button'
    {tabindex}
    on:click={() => clickOnFlag(id)}
    on:keypress={() => {}}
    style:color
    style:background
  >
    <div class='textOnly'>{iso}</div>
  </div>
{/if}
<style lang='scss'>
  .flag-wrapper {
    position: relative;
    display: inline-block;
    width: 2.4rem;
    height: 2.4rem;
    font-weight: bold;
    font-size: 1rem;
    font-variant: small-caps;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;

    > .language {
      position: absolute;
      right: 0.5rem;
      bottom: 0.2rem;
    }

    > .languageName {
      position: absolute;
      top: 0.2rem;
      left: 0.2rem;
      color: var(--text-primary-color);
    }

    &.-full {
      padding: 0 1rem;

      > .languageName {
        position: relative;
        top: unset;
        left: unset;
      }
    }

    &.-active {
      position: relative;
      border: none;
      outline: solid var(--Primary-Medium) 0.2rem;
    }

    &.-small {
      width: 1.6rem;
      height: 1.6rem;

      > .language {
        position: relative;
        right: auto;
        bottom: 0;
        font-size: 0.8rem;
        color: var(--Gray-Darker);
      }
    }

    &.-noClick {
      pointer-events: none;
    }
  }
</style>
