<script lang='ts'>
  import { createEventDispatcher } from 'svelte'

  import { _ } from '@/helpers/i18n'
  import { toInt } from '@/helpers/mixHelpers'

  import Icon from '@/components/icons/Icon.svelte'
  import FakeButton from '@/components/ui/FakeButton.svelte'
  import LanguageSelect from '@/components/ui/LanguageSelect.svelte'

  import { ForumLanguage } from '@/definitions/langoid'

  export let languages: ForumLanguage[] = [],
    canBeEmpty = false,
    type: 'known' | 'learning' = 'known'

  const dispatcher = createEventDispatcher()
  const handleAddingLanguages = (languageId: number, i: number) => {
    if (languages[i] === undefined) {
      languages[i] = { lang: 0 }
    }
    languages[i].lang = languageId || 0
  }

</script>
<div class='language-items'>
  {#each Object.values(languages || {}) as langGroup, i}
    <div class='selected-item _row'>
      <LanguageSelect onChange={(lang) => { handleAddingLanguages(lang, i) }} selectedLanguage={toInt(languages[i]?.lang)} />
      {#if canBeEmpty || (Object.values(languages || {}).length > 1)}
        <FakeButton onClick={() => { languages.splice(i, 1); languages = languages }}>
          <Icon icon='Trash' weight='regular' />
        </FakeButton>
      {/if}
    </div>
  {/each}
  <div class='add-button'>
    <button class='_transparent-blue' type='button' on:click={() => { languages = [...languages, { lang: 0 }] }}>{$_('forum.addLanguage')}</button>
  </div>
  <div class='save-button'>
    {#if canBeEmpty || languages?.length}
      <button class='_maxwidth-btn' type='button' on:click={() => dispatcher('save', { languages, type })}>{$_('save')}</button>
    {/if}
  </div>
</div>

<style lang='scss'>
  .language-items {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    align-items: baseline;
    padding: 0.4rem 0;
    background-size: contain;
  }

  .selected-item {
    width: 100%;
    padding: 0.4rem 1.6rem;
  }

  .add-button {
    width: 100%;
    padding: 0 1.6rem 0.8rem;
  }

  .save-button {
    width: 100%;
    padding: 0.8rem 1.6rem;
  }
</style>
