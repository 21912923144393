<script lang='ts'>
  import { onDestroy, onMount } from 'svelte'
  import { link } from 'svelte-routing'

  import { getCookie } from '@/helpers/apiCall'
  import { fetchData } from '@/helpers/fetchHelpers'
  import { toInt } from '@/helpers/mixHelpers'

  import AddTopicForm from '@/components/forum/AddTopicForm.svelte'
  import Icon from '@/components/icons/Icon.svelte'
  import FakeButton from '@/components/ui/FakeButton.svelte'

  import { CategoryTopic, ForumCategory, ForumLanguage, ForumTranslation, LanguageGroups, UserData } from '@/definitions/langoid'
  import { _ } from '@/libs/i18n'
  import { forumConfig, userStorredConfig } from '@/store'

  export let iso = '',
    categories: Record<string, ForumCategory[]> = { 0: [] },
    currentCategory = {},
    myTranslations: Record<number, any> = {},
    topics: CategoryTopic[] = [],
    allCategoriesList: { id: number, title: string }[],
    translations: Record<string, ForumTranslation[]> = {},
    known: ForumLanguage[] = [],
    knownIndexes: number[] = [],
    userId = 0,
    group: 'latest' | 'untranslated' | 'bookmarks' | 'popular' | 'categories' = 'latest'

  let formShown = false,
    user: UserData,
    mobileShown = false

  $ : user = $userStorredConfig

  $: if (categories) {
    allCategoriesList = Object.keys(categories).filter(key => parseInt(key) > 0).flatMap(key => {
      return categories[key].map(item => ({ id: item.id, title: item.title }))
    })
  }

  let unsubscribe: () => void
  onMount(async () => {
    const jwt = getCookie('jwt')
    if (jwt) {
      unsubscribe = forumConfig.subscribe(async (config: LanguageGroups) => {
        let data
        if (config.known.length) {
          data = await fetchData('forum/load', { config: config || {}, iso })
          known = config.known
        } else {
          data = await fetchData('forum/load', { config: { known: [], learn: [] }, iso })
          known = data.known
        }
        categories = data.categories
        translations = data.translations
        userId = data.userId
        knownIndexes = Object.values(known).map(el => toInt(el.lang)).filter(el => el > 0)
      })
    } else {
      const data = await fetchData('forum/load', { config: { known: [], learn: [] }, iso })
      known = data.known
      categories = data.categories
      translations = data.translations
      userId = data.userId
      knownIndexes = Object.values(known).map(el => toInt(el.lang)).filter(el => el > 0)
    }
  })

  const showMobile = () => {
    mobileShown = !mobileShown
  }

  onDestroy(() => {
    if (unsubscribe) unsubscribe()
  })

</script>

<div class='_mobileOnly'>
  <FakeButton onClick={showMobile}>
    <span class='filter-icon'>
      <Icon icon='Funnel' size={20} weight='regular' />
      <Icon icon={mobileShown ? 'CaretUp' : 'CaretDown'} size={16} weight='regular' />
    </span>
  </FakeButton>
</div>
<div class='forum-tab-filters'>
  <div class='additional-pages' class:-mobileShown={mobileShown}>
    <a class='button _tertiary-btn' class:-active={group==='latest'} href={`/${iso}/forum/latest`} use:link>{$_('forum.latest')}</a>
    <a class='button _tertiary-btn' class:-active={group==='untranslated'} href={`/${iso}/forum/untranslated-posts`} use:link>{$_('forum.untranslated')}</a>
    <a class='button _tertiary-btn' class:-active={group==='bookmarks'} href={`/${iso}/forum/user-bookmarks`} use:link>{$_('forum.userBookmarks')}</a>
    <a class='button _warning-btn' class:-active={group==='categories'} href={`/${iso}/forum/categories`} use:link>{$_('forum.categories')}</a>
  </div>
  <div class='add-topic-section'>
    <FakeButton class='_maxwidth-btn' onClick={() => { formShown = !formShown }}>
      <span class='_filledicon' title={$_('forum.addTopic')}><Icon icon='Plus' size={20} weight='regular' /></span>
    </FakeButton>
    {#if user.id && formShown}
      <AddTopicForm
        {allCategoriesList}
        {user}
        bind:formShown
        bind:myTranslations
        bind:currentCategory
        bind:topics
      />
    {/if}
  </div>
</div>

<style lang='scss'>
  .filter-icon {
    padding: 0.4rem;
    border: 0.1rem solid var(--Gray-Medium);
    border-radius: 0.8rem;
  }

  .forum-tab-filters {
    position: relative;
    display: flex;
    gap: 0.4rem;
    align-items: center;
    margin-left: 0.4rem;
  }

  ._tertiary-btn {
    &.-active {
      color: var(--Base-White);
      background-color: var(--Primary-Medium);
      border: 0.2rem solid var(--Primary-Medium);

      &:hover {
        background-color: var(--Primary-Dark);
        border: 0.2rem solid var(--Primary-Dark);
      }
    }
  }

  ._warning-btn {
    &.-active {
      border: 0.2rem solid var(--Primary-Medium);
    }
  }

  .additional-pages {
    display: flex;
    gap: 0.4rem;
    align-items: center;
  }

  @media (max-width: 768px) {
    .additional-pages {
      position: absolute;
      top: 4rem;
      right: 0;
      display: none;
      flex-direction: column;
      gap: 0;
      align-content: flex-start;
      align-items: flex-start;
      overflow: hidden;
      border: solid var(--Gray-Light) 0.1rem;
      border-radius: 0.8rem;
      box-shadow: var(--Shadow-X);

      &.-mobileShown {
        display: flex;
      }

      > .button {
        min-width: 16rem;
        border-color: transparent;
        border-radius: 0;
      }
    }

    .add-topic-section {
      position: fixed;
      right: 3rem;
      bottom: 3rem;
    }
  }
</style>
